.main-footer {
  .section-footer-benefit {
    .container-footer-benefit {
      .title {
        color: var(--c_grey_600);
        text-align: center;
      }

      .container-footer-benefit-box {
        display: grid;
        grid-template-columns: repeat (1fr);
        gap: 16px;
        margin-top: 20px;

        .footer-benefit-box {
          padding: 16px 10px;
          border: 1px solid var(--c_grey_200);
        }

        .footer-benefit-box-text {
          .text-footer-benefit,
          .t-link {
            margin-left: 30px;
            margin-bottom: 8px;
          }
        }

        .footer-benefit-box-title {
          display: flex;

          .t-p {
            margin-left: 12px;
            font-weight: 600;
          }

          .icon-benefit {
            color: var(--c_primary);
          }
        }

        .s-arrow-right-long-tail {
          margin-left: 4px;
          color: var(--c_primary);
          vertical-align: sub;
        }
      }
    }

    .container-button-benefit-call-to-action {
      display: flex;
      justify-content: center;
    }

    .button-benefit-call-to-action {
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
}
