@use 'mice_header_commons' as *;

body {
  &:not(.inspiration-guide) {
    .mice-header {
      display: none;
    }

    .mobile-mice-header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 8000;
      width: 100%;

      &.burgermenu-sticky-head {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
      }

      .banner-back {
        flex: 0 0 100%;
        height: 30px;
        margin-bottom: 20px;
        background: var(--c_primary);

        .wrapper {
          padding: 0 20px;
        }

        a {
          @include icon (arrow-left-fat, after, 'xs') {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
          position: relative;
          padding-left: 20px;
          color: white;
          line-height: 30px;
        }
      }

      .logo {
        flex: 0 0 auto;

        .logo-mice-img {
          display: block;
        }

        &.dark {
          display: none;
        }
      }

      .mobile-menu-toggle {
        flex: 0 0 auto;
        margin-top: 10px;

        &::after,
        &::before {
          background: white;
        }

        span {
          background: white;
        }
      }

      &.with-bg {
        position: relative;

        .logo {
          &.dark {
            display: block;
          }

          &.light {
            display: none;
          }
        }

        .mobile-menu-toggle {
          &::after,
          &::before {
            background: var(--c_primary);
          }
        }
      }
    }

    .opened-left {
      body.mice-home {
        .page-wrapper {
          margin-top: 0;
        }

        .mobile-menu-toggle {
          border-left: none;
        }
      }
    }
  }
}
