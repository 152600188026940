@use 'modal_commons' as *;

.modal {
  &--bottom {
    position: absolute;
    bottom: 0;
  }

  .modal-header {
    transform: translateZ(0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    padding: 2em 1em;
    box-shadow: 2px 2px 8px 0 rgba(var(--c_grey_rgb), 0.5);
    background: var(--c_white);
    transition: none;

    &__content {
      width: 47%;

      &--text {
        @include fontSize(16);

        @include icon(arrow-left-fat, before, 's') {
          margin-right: 8px;
          color: var(--icon-color-default);
        }
        display: flex;
        align-items: center;
        font-weight: var(--sans-bold);
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &--contact {
        @include fontSize(14);
        margin-right: 8px;
        color: inherit;

      }
    }
  }
}

@keyframes mmslideIn {
  from {
    transform: translateX(20%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(20%);
  }
}

@keyframes mmslideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes mmslideOutBottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(10%);
    opacity: 0;
  }
}

.micromodal-slide {
  &[aria-hidden='false'] .modal__container {
    &.modal--bottom {
      animation: mmslideInBottom 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] .modal__container {
    &.modal--bottom {
      animation: mmslideOutBottom 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
