@use 'cookies_content_commons' as *;

.cookies-content {
  .privacy_prompt_footer {
    flex-direction: column;

    &--links {
      margin-bottom: 16px;

      .link-in-cookies-message {
        &:first-child {
          margin-right: 40px;
        }
      }
    }

    &--buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }
  }
}
