@use 'layout_commons' as *;

body {
  &:not(.inspiration-guide) {
    &:not(.is-fullscreen) {
      padding-top: 65px;
    }

    &.in-app {
      padding-top: 0;
    }

    &.mice-home {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.hotel-one-landing-page,
    &.transparent-header {
      padding-top: 0;
    }

    &.has-mobile-cta-sticky-bar {
      padding-bottom: 127px; // height of mobile fastbooking bottom
    }

    .page-wrapper {
      position: relative;
    }
  }
}

.wrapper,
%wrapper {
  padding: 0 20px;
}

.cms-zones {
  @include bg-half(90%);
  flex-direction: column;
  padding: 0;

  > .half {
    width: 100%;
  }

  // overrides some bg-half styles
  &::after {
    top: 0;
  }
}

.mobile-only {
  display: block;

  &.l-external,
  &.secondary-btn,
  &.btn-primary {
    display: inline-block;
  }
}

.mobile-only-flex {
  display: flex;
}

.hide-on-mobile {
  display: none !important;
}
