@use 'burgermenu_commons' as *;

$margin-top: 60px;

body {
  &:not(.inspiration-guide) {
    .opened-left,
    .opened-right {
      .page-wrapper {
        margin-top: $margin-top;
      }
    }
  }
}
