@use '../../shared/internal_nav/internal_nav_mobile';
@use '../../shared/hotel_breadcrumb/hotel_breadcrumb_mobile';
@use '../../shared/header_sticky_hotel/header_sticky_hotel_mobile';
@use '../../shared/hotel_header_intro/hotel_header_intro_mobile';

.hotel-header-lite-wrapper {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  margin-bottom: 30px;

  .vue-hotel-header-lite,
  .hotel-header-lite {
    width: 100%;
    height: 100%;
  }
}

.hotel-header-lite {
  &__fastbooking {
    display: none
  }
}
