@use 'header_commons' as *;
@use '../../vendors/burgermenu/burgermenu_mobile';
@use '../../elements/user_image/user_image_mobile';
@use '../../shared/ihg_tooltips/ihg_tooltip_mobile';
@use '../../shared/ihg_modal/ihg_modal_mobile';
@use '../../vue/hotel_header_lite/hotel_header_lite_mobile';

body {
  &:not(.inspiration-guide) {
    .main-header {
      z-index: 15;
      display: none !important;

      #logo-iberostar,
      .logo-green {
        display: none;

        body.ada & {
          display: block !important;
        }
      }
    }

    .user-notifications {
      display: none;
    }
  }
}
