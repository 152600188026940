@use 'ihg_tooltip_commons' as *;

.tooltip-wizard {
  &__background-color {
    position: fixed;
    top: 0;
    z-index: 9998;
    opacity: 0.7;
    width: 100%;
    height: 101%;
    background: var(--c_blue_700);
    background: radial-gradient(circle at 40px 35px, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 0%) 30px, var(--c_blue_700) 30px);
  }

  &__content {
    z-index: 9999;

    [data-tippy-root] {
      .tippy-box {
        transform: translate3d(8%, 40%, 10px);
      }
    }
  }
}
