@use 'user_image_commons' as *;

.user-image {
  &.user-image-flex {
    display: flex;
  }

  &.user-image-with-letter {
    &.without-name {
      .user-image-letter {
        &::after {
          font-size: 50px;
        }
      }
    }
  }
}
