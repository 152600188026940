@use 'ihg_modal_commons' as *;

.modal-ihg {
  .tooltips {
    top: 12%;
    left: 5%;
    width: 90%;
    height: fit-content;
  }

  .modal__header {
    padding: 20px;
  }

  &__header {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .modal__content {
    padding: 0 20px 20px;
  }

  &__content {
    padding: 0;
    padding-top: 25px;
    border-top: 1px solid #DBDBDB;

    .ihg-link {
      font-size: 14px !important;
    }
  }

  .content {
    &__subtitle-modal-ihg {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;

      .circle-user-level {
        margin-right: 8px;
      }
    }

    &__title-modal-ihg {
      font-size: 26px;
      line-height: 32px;
    }
  }
}
