@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.mobile-cta-sticky-bar {
  @include bg-transition;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 8900;
  transform: translateY(100%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  outline: 0;
  box-shadow: 2px 2px 8px 0 rgba(var(--c_grey_rgb), 0.5);
  background: var(--c_white);
  -webkit-overflow-scrolling: touch;

  &__container {
    display: flex;
    flex: 1 0 100%;
    width: 100%;
  }

  &__mobile-hotel-explore {
    @include icon(bars, before, 'm', true){
      margin-right: 10px;
    }
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: 10px;
  }

  &__cta-btn {
    flex-grow: 1;
    width: 100%;
    max-width: none;
    margin-left: 8px;
    font-size: 12px;
    text-transform: uppercase;
  }

  .cta-btn {
    .price-from > * {
      font-weight: var(--sans-bold);
    }

    .price, .coin {
      font-size: 12px;
    }
  }

  &__mobile-call-center {
    @include icon(phone, before, 'm', true) {
      margin-right: 10px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 145px;
    padding: 16px 8px 13px;
    text-align: center;
  }

  &__summary-item {
    cursor: pointer;
    width: 100%;
    padding: 5px 10px;
    overflow: hidden;
    border: 1px solid var(--c_grey_200);
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .summary-item {
    &__label {
      display: block;
      font-size: 10px;
    }

    &__info {
      @include fontSize(16);
      max-height: 48px;
      overflow: hidden;
      color: var(--c_dark_grey);
      white-space: initial;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    &.label-hidden {
      display: flex;
      align-items: center;

      .summary-label {
        display: none;
      }
    }
  }

  &.hidden {
    position: fixed;
    top: -100%;
  }

  &.accesible {
    padding: 5px;

    .secondary-btn,
    .btn-primary {
      padding: 5px;
    }

    .secondary-btn {
      &::before {
        @include fontSize(15);
      }
    }
  }
}
