@use 'ib_swiper_commons' as *;

.ib-swiper {
  .swiper-wrapper {
    min-height: 100%;
  }

  &--template-fullscreen , &--template-coverflow {
    &.is-fullscreen {
      display: flex;
      height: 100%;
      background-color: var(--c_white);

      .swiper-wrapper {
        height: fit-content;
        margin: auto;
      }
    }

    .swiper-exit-fullscreen {
      @include icon(collapse, after, 'l') {
        color: var(--c_primary);
      }
    }
  }

  &--template-scrollbar {
    .swiper-scrollbar{
      position: relative;
      top: 0;
      bottom: 0;
    }

    .swiper-pagination{
      position: relative;
      top: 0;
      bottom: 0;
      display: flex;
      gap: 3px;
      align-items: center;
      justify-content: flex-end;
      color: var(--c_turquoise_dark);

      &-current{
        color: var(--c_primary);
      }
    }

    .swiper-navigation{
      display: flex;
      align-items: center;
      justify-content: center;

      .swiper-button-prev, .swiper-button-next {
        position: relative;
        inset: 0;
        margin: 0;
      }
    }
  }

  &--template-coverflow{
    .swiper-navigation-wrapper{
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;

    }
  }

}
