@use 'tooltip_commons' as *;

.basic-tooltip {
  padding: 10px;

  &.top {
    left: calc(-80px + 1vw);
  }

  &.bottom {
    transform: translateX(-70%);
  }

  .tooltip-desc {
    @include fontSize(12);
    line-height: 16px;
  }
}
