@use 'hotel_header_intro_commons' as *;

.hotel-header-intro {
  .wrapper {
    .info-container {
      margin-bottom: 15px;

      > * {
        flex: 0 0 100%;
        justify-content: space-between;
      }

      .badge {
        flex: 0 0 auto;
        margin-bottom: 8px;
      }
    }

    .stars {
      top: -1px;
    }

    .btn-primary {
      width: 100%;
    }
  }
}
