@use 'destination_breadcrumb_commons' as *;

.destination-breadcrumb {
  .wrapper {
    padding: 0;
  }

  .destination-list {
    justify-content: left;
  }

  .b-hotels-services-filter & {
    display: block;
    margin-bottom: 20px;

    .wrapper.flex {
      justify-content: left;
      max-width: 100%;
      padding: 13px 0;
      overflow-y: scroll;
    }

    > ul {
      padding: 8px 0;
    }

    .filter {
      padding: 0 16px;
    }

    .sticky {
      top: 0;
    }
  }

  .destination-header & {
    background-color: var(--c_white);

    .has-children {
      padding: 0 20px;

      .drop-down-links {
        right: -48px;
      }
    }

    .destination-list {
      margin-bottom: 10px;
    }
  }

  .drop-down-links {
    top: 8px;

    &::before {
      display: none;
    }
  }
}
