@use 'mobile_header_commons' as *;

.mobile-header {
  height: auto;

  .logo-white {
    display: none;
  }

  .logo-green {
    display: block;
    width: 100%;
    text-align: left;
  }

  .mobile-header-cnt {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    width: 100%;
    height: 65px;
    background: transparent;

    .circle-notifications {
      &.hidden {
        display: none;
      }
    }

    .mobile-call-center {
      padding-right: 10px;
      margin-bottom: 10px;
      color: var(--c_black_grey_700);

      & > .s {
        font-size: 20px;
      }

      .mobile-button-text {
        color: var(--c_black_grey_700);
        text-wrap: nowrap;
      }
    }
  }

  .mobile-menu-toggle {
    &__text {
      color: var(--c_black_grey_700);
      font-weight: 600;
    }
  }

  .transparent-header &.initial {
    background: linear-gradient(to bottom, var(--c_black), transparent);
  }

  .w-headband.hotel-one-landing-page &.initial,
  .w-headband .transparent-header &.initial {
    margin-top: 0.2%;
  }

  .auth0-login-wrapper {
    display: flex;
  }
}

/* Cabe la posibilidad de que un móvil girado pueda llegar a ser un
  small-desktop
  Es por ello que se debe incluir el mixin que hay en el commons para
  que si Akamai nos dice que esto es un Mobile,
  mantenga estos estilos si giramos y el dispositivo es muy grande de ancho
*/
@include media(1600px) {
  @include tablet-mobile-styles;
}
